import "../sass/main-child.scss";
import WhoisForm from "./components/whois-form";
import ProviderForm from "./components/provider-form";
import ContactForm from "./components/contact-form";
import SignupFormHs from "./components/signup-form-hs";
import FullWidthSlider from "./components/full-width-slider";

document.addEventListener("DOMContentLoaded", () => {
  window.customElements.define("whois-form", WhoisForm);
  window.customElements.define("provider-form", ProviderForm);
  window.customElements.define("contact-form", ContactForm);
  window.customElements.define("signup-form-hs", SignupFormHs);
  window.customElements.define("full-width-slider", FullWidthSlider);
});
