export default class ProviderForm extends HTMLElement {
  connectedCallback() {
    this.resolveElements();
    this.addListeners();
  }

  resolveElements() {
    this.form = this.querySelector("form");
    this.providerInput = this.querySelector("[data-input-provider]");
    this.msgWrapper = this.querySelector("[data-msg-wrapper]");
    this.submitBtn = this.querySelector("[type='submit']");
  }

  addListeners() {
    this.form.addEventListener("submit", this.onFormSubmit.bind(this));
  }

  onFormSubmit(e) {
    e.preventDefault();

    if (this.isFormDisabled || this.providerInput.value.length < 1) {
      return;
    }

    this.makeQuery();
  }

  makeUiLoading() {
    this.providerInput.disabled = true;
    this.submitBtn.classList.add("btn--loading");
  }

  makeUiNotLoading() {
    this.providerInput.disabled = false;
    this.submitBtn.classList.remove("btn--loading");
  }

  get isFormDisabled() {
    return this.providerInput.disabled;
  }

  async makeQuery() {
    this.makeUiLoading();
    const host = `${wp_global_data?.home_url}/wp-json/api/v1`;
    const endpoint = `${host}/find-provider/`;
    const resp = await fetch(endpoint, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        provider: this.providerInput.value,
      }),
    });
    const data = await resp.json();

    if (resp.status === 200) {
      this.makeUiNotLoading();
      this.providerInput.focus();
    } else {
      console.error(data);
      this.makeUiNotLoading();
      this.providerInput.focus();
      this.showMsg("Request error");
      return;
    }

    let msg = "";

    if (!data) {
      msg = `Unable to process results.`;
      return;
    }

    var formValue = this.providerInput.value;
    if (data === "domain not found") {
      if(formValue !== formValue.toLowerCase()){
        msg = `The search field only supports lower case characters.`;
      }else{
        msg = `The domain <strong>${formValue}</strong> was not found in our reseller database.`;
      }
    } else {
      msg = `The domain registration of <strong>${formValue}</strong> is managed by <strong>${data}</strong>`;
    }

    this.showMsg(msg);
  }

  showMsg(msg) {
    if (!msg) {
      this.msgWrapper.innerHTML = ``;
    } else {
      this.msgWrapper.innerHTML = `<p>${msg}</p>`;
    }
  }

  disconnectedCallback() {}
}
