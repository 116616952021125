export default class SignUpForm extends HTMLElement {
  constructor(...args) {
    const self = super(...args);
    self.init();
    return self;
  }

  init() {}

  connectedCallback() {
    window.addEventListener("message", (event) => {
      if (
        event.data.type === "hsFormCallback" &&
        event.data.eventName === "onFormReady"
      ) {
        // the form is ready
        this.prepareHsFormDom();
      }
    });
  }

  prepareHsFormDom() {
    this.form = this.querySelector("form");
    this.formDirectChilds = this.form.querySelectorAll(":scope > *");

    const group1 = document.createElement("div");
    group1.classList.add("group-1");
    const group2 = document.createElement("div");
    group2.classList.add("group-2");

    this.formDirectChilds.forEach((element) => {
      const shouldGoToRightColumn =
        element.querySelector(
          '[name="contact_comments"], .multi-container, .hs-form-booleancheckbox'
        ) !== null;
      const hasSubmitButton = element.querySelector('[type="submit"]') !== null;

      if (shouldGoToRightColumn && !hasSubmitButton) {
        group2.appendChild(element);
      }

      if (!shouldGoToRightColumn && !hasSubmitButton) {
        group1.appendChild(element);
      }

      if (hasSubmitButton) {
        setTimeout(() => {
          element.classList.add("group-3");
          this.form.appendChild(element);
        }, 0);
      }
    });

    this.form.appendChild(group1);
    this.form.appendChild(group2);
  }

  disconnectedCallback() {}
}
