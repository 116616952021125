export default class FullWidthSlider extends HTMLElement {
    connectedCallback() {
        this.resolveElements();
        if(this.slides){
          var self = this;
          this.slides.forEach(function(slide) {
            document.querySelector('input[data-suid="'+slide.id+'"]').checked = true;
              self.startSlide(slide);
          })
        }
      }
    
      resolveElements() {
          this.slides = document.querySelectorAll('full-width-slider');
      }
  
      startSlide(slide){
          setInterval( () => {
              this.switchNextSlide(slide);
            }, 7000 );
      }
  
    switchNextSlide(slide){
        var radios = document.querySelectorAll('input[data-suid="'+slide.id+'"]');
        var currentChecked = -1;
        var nextChecked = -1;
        [].forEach.call(radios, el => {
            if(el.checked){
                var elId = el.id;
                var ret = elId.replace(slide.id+'-image','');
                currentChecked = parseInt(ret);
            }
        });
        var total = radios.length;
        if(currentChecked < total){
            nextChecked = currentChecked + 1;
        }
        if(currentChecked == total){
            nextChecked = 1;
        }
        var currentCheckedId = slide.id+'-image'+currentChecked;
        var nextChckedId = slide.id+'-image'+nextChecked;
        document.getElementById(currentCheckedId).checked = false;
        document.getElementById(nextChckedId).checked = true;
    }
  
    disconnectedCallback() {}
  }
  