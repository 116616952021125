import JustValidate from "just-validate";

export default class ContactForm extends HTMLElement {
  constructor(...args) {
    const self = super(...args);
    self.init();
    return self;
  }

  init() {}

  connectedCallback() {
    this.resolveElements();
    this.setValidator();
    this.addListeners();
  }

  resolveElements() {
    window.onloadCallback = () => {
      this.onloadCallback();
    }; 
    this.form = this.querySelector("form");
    this.submitBtn = this.querySelector("[type='submit']");
    this.msgWrapper = this.querySelector("[data-msg-wrapper]");
    this.salesForceEl = this.querySelector("[data-sales-force-area]");
  }

  setValidator() {
    this.validation = new JustValidate(
      this.form,
      {
        focusInvalidField: true,
        errorLabelStyle: {
          color: "#EB2128",
          fontSize: "12px",
          lineHeight: "1",
          position: "absolute",
          top: "calc(100% + 1px)",
        },
      },
      [
        {
          key: "required",
          dict: {
            en: "This field is required",
            de: "Dieses Feld ist erforderlich",
          },
        },
        {
          key: "emailInvalid",
          dict: {
            en: "Email address incorrect",
            de: "E-Mail-Adresse falsch",
          },
        },
        {
          key: "recaptchaInvalid",
          dict: {
            en: "Please check the reCaptcha",
            de: "reCaptcha falsch",
          },
        },
      ]
    );

    this.validation.setCurrentLocale("en");

    this.validation
      .addField(this.querySelector("[name='origin']"), [
        {
          rule: "required",
          errorMessage: "required",
        },
      ])
      .addField(this.querySelector("[name='fname']"), [
        {
          rule: "required",
          errorMessage: "required",
        },
        {
          rule: "minLength",
          value: 2,
        },
      ])
      .addField(this.querySelector("[name='lname']"), [
        {
          rule: "required",
          errorMessage: "required",
        },
        {
          rule: "minLength",
          value: 2,
        },
      ])
      .addField(this.querySelector("[name='email']"), [
        {
          rule: "required",
          errorMessage: "required",
        },
        {
          rule: "email",
          errorMessage: "emailInvalid",
        },
      ])
      .addField(this.querySelector("[name='phone']"), [
        {
          rule: "required",
          errorMessage: "required",
        },
      ])
      .addField(this.querySelector("[name='recaptcha']"), [
        {
          validator: (value, context) => {
            var response = grecaptcha.getResponse();
            if(response.length == 0){
              return false;
            }
            else{
              return true;
            }
          },
          errorMessage: "recaptchaInvalid",
        },
      ]);
  }

  addListeners() {
    this.form.addEventListener("submit", this.onFormSubmit.bind(this));
  }

  onFormSubmit(e) {
    console.log("submit");
    e.preventDefault();

    if (this.isFormDisabled || !this.validation.isValid) {
      return;
    }

    this.makeQuery();
  }

  makeUiLoading() {
    this.submitBtn.disabled = true;
    this.submitBtn.classList.add("btn--loading");
  }

  makeUiNotLoading() {
    this.submitBtn.disabled = false;
    this.submitBtn.classList.remove("btn--loading");
  }

  get successMsg() {
    return "Thank you for contacting TPP Wholesale. A member of our reseller team will contact you as soon as possible";
  }

  get errorMsg() {
    return "Error, couldn't submit form, please try again";
  }

  get isFormDisabled() {
    return this.submitBtn.disabled;
  }

  get formData() {
    const formData = new FormData(this.form);
    return Object.fromEntries(formData);
  }

  get salesAdditionalFormData() {
    const data = new FormData();
    data.append("Lead_Source_Other__c", "TPP - Website Sales");
    data.append("Activity_Summary__c", "Contact Us Sales");
    data.append("oid", "00D0Y000001fly4");
    data.append(
      "retURL",
      "http://www.tppwholesale.com.au/contact/?success#form"
    );
    data.append("lead_source", "Website");
    data.append("currency", "AUD");
    return Object.fromEntries(data);
  }

  get formDataValuesWithSalesData() {
    const formData = new FormData(this.form);
    const formEntries = Object.fromEntries(formData);

    return { ...formEntries, ...this.salesAdditionalFormData };
  }

  get isSalesSelected() {
    return this.formData?.origin === "sales" ? true : false;
  }

  appendSalesForceArea() {
    this.salesForceEl.innerHTML = `<div class="salesforceArea"><input type="hidden" id="Lead_Source_Other__c" name="Lead_Source_Other__c" value="TPP - Website Sales"><input type="hidden" id="Activity_Summary__c" name="Activity_Summary__c" value="Contact Us Sales"><input type="hidden" name="oid" value="00D0Y000001fly4"><input type="hidden" name="retURL" value="${window.location.origin}${window.location.pathname}?success#thankyou"><select class="hidden" style="display: none;" id="lead_source" name="lead_source"><option value="Website">Website</option></select><select class="hidden" style="display: none;" id="currency" name="currency"><option value="AUD">AUD - Australian Dollar</option></select></div>`;
    this.form.action =
      "https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8";
  }

  clearSalesForceArea() {
    this.salesForceEl.innerHTML = "";
    this.form.action = "";
  }

  async makeQuery() {
    this.showMsg("");
    this.makeUiLoading();
    const sendingData = this.formData;
    const host = `${wp_global_data?.home_url}/wp-json/api/v1`;
    const endpoint = `${host}/contact-form/`;

    if (this.isSalesSelected) {
      this.appendSalesForceArea();
      this.form.submit();
      return;
    } else {
      this.clearSalesForceArea();
    }

    try {
      const resp = await fetch(endpoint, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(sendingData),
      });

      const data = await resp.json();
      console.log(data);

      if (resp.ok) {
        this.makeUiNotLoading();
        this.showMsg(this.successMsg);
        this.form.reset();
      } else {
        this.makeUiNotLoading();
        this.showMsg(this.errorMsg);
      }
    } catch (error) {
      this.makeUiNotLoading();
      this.showMsg(this.errorMsg);
    }
  }

  showMsg(msg) {
    if (!msg) {
      this.msgWrapper.innerHTML = ``;
    } else {
      this.msgWrapper.innerHTML = `<p>${msg}</p>`;
    }
  }

  onloadCallback() {
    console.warn("ReCaptcha Ready!");
  };
  
  disconnectedCallback() {}
}
