export default class WhoisForm extends HTMLElement {
  connectedCallback() {
    this.resolveElements();
    this.addListeners();
  }

  resolveElements() {
    this.form = this.querySelector("form");
    this.domainInput = this.querySelector("[data-input-domain]");
    this.msgWrapper = this.querySelector("[data-msg-wrapper]");
    this.submitBtn = this.querySelector("[type='submit']");
  }

  addListeners() {
    this.form.addEventListener("submit", this.onFormSubmit.bind(this));
  }

  onFormSubmit(e) {
    e.preventDefault();

    if (this.isFormDisabled || this.domainInput.value.length < 1) {
      return;
    }

    this.makeQuery();
  }

  makeUiLoading() {
    this.domainInput.disabled = true;
    this.submitBtn.classList.add("btn--loading");
  }

  makeUiNotLoading() {
    this.domainInput.disabled = false;
    this.submitBtn.classList.remove("btn--loading");
  }

  get isFormDisabled() {
    return this.domainInput.disabled;
  }

  async makeQuery() {
    console.log(this.isFormDisabled);
    this.makeUiLoading();
    const endpoint = `https://audapublic.azurewebsites.net/api/public/avail/${this.domainInput.value}`;
    const resp = await fetch(endpoint);
    const data = await resp.json();

    if (resp.ok) {
      this.makeUiNotLoading();
      this.domainInput.focus();
    } else {
      console.log(resp);
      this.makeUiNotLoading();
      this.domainInput.focus();
    }

    this.msgWrapper.innerHTML = "";
    if (
      data?.domainList !== undefined &&
      data?.domainList !== null &&
      data?.domainList
    ) {
      this.msgWrapper.innerHTML =
        "<table class='whois-form__table'><thead><tr><td><strong>Domain</strong></td><td><strong>TLD</strong></td><td><strong>Status</strong></td></tr></thead><tbody></tbody></table>";
      data.domainList.forEach((item, index) => {
        const label =
          item?.reason === "In use" ? "Already Taken" : "✅ Available";

        setTimeout(() => {
          this.msgWrapper
            .querySelector("table tbody")
            .insertAdjacentHTML(
              "beforeend",
              `<tr><td>${item.name}</td><td>${item.ext}</td><td>${label}</td></tr>`
            );
        }, index * 75);
      });
    }
  }

  disconnectedCallback() {}
}
